header {
    text-align: center;

    margin: 0;
    padding: 30px 0px;

    color: #626e6e;

    text-transform: uppercase;

    display: grid;

    h1:first-child {
        letter-spacing: 0.65rem;
    }

    h1 {
        margin: 2px;
        font-size: 1.5rem;
        letter-spacing: 0.5rem;
    }
}


header:focus,
header:active,
header:hover

{
#upper {
    animation-duration: 1.5s;
    animation-name: slideLeft;
}

#lower {
    animation-duration: 1.5s;
    animation-name: slideRight;
}
}

@keyframes slideLeft {
0% {
    margin-right: 0.2%;
}
50% {
    margin-right: 4%;
}
100% {
    margin-right: 0.2%;
}

}
@keyframes slideRight {
0% {
    margin-left: 0.2%;
}
50% {
    margin-left: 4%;
}
100% {
    margin-left: 0.2%;
}

}
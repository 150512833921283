// p{
//     color: #626e6e;
//     font-size: 1.2rem;
// }

body {
    .container_app {
        background-color: #fff;

        max-width: 75%;
        margin: 0 auto;

        display: grid;

        grid-template-columns: 50% 50%;

        padding: 15px;
        border-radius: 20px;

        .container_app_left {
            grid-column: 1;

            margin-right: 15px;
        }

        .container_app_right {
            background-color: #1f1f1f;
            grid-column: 2;

            border-radius: 15px;

            padding: 5px 10px;

            p {
                color: #ffffff;
                font-size: 1.2rem;
            }

            .grid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding : 40px;
            }

            .output {
                font-size: 2rem;
                font-weight: 700;
                color: #6f55ff;
            }
        }
    }
}

	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/10/2020 16:15
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: inherit;
font-style: normal;
color: inherit;
}

.flaticon-arrow-down-sign-to-navigate:before { content: "\f100"; }
.flaticon-right-arrow:before { content: "\f101"; }
.flaticon-right:before { content: "\f102"; }
.flaticon-right-arrow-1:before { content: "\f103"; }
.flaticon-right-thin-arrow-angle:before { content: "\f104"; }
.flaticon-arrow-point-to-right:before { content: "\f105"; }
.flaticon-right-arrow-angle:before { content: "\f106"; }
.flaticon-right-arrow-angle-1:before { content: "\f107"; }
.flaticon-menu-of-three-lines:before { content: "\f108"; }
.flaticon-menu-square-button:before { content: "\f109"; }
.flaticon-checked:before { content: "\f10a"; }
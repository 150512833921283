input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

label {
    color: #626e6e;
    font-size: 1.2rem;
}

.input {
    background-color: #f3f8fb;
    border: 0;
    text-align: right;

    font-size: 1.5rem;
}
.input_dollar {
    // background: url("../assets/images/icon/icon-dollar.svg") 0 0 no-repeat;
    background-color: #f3f8fb;
    background-size: 15px;
    background-position-y: center;
    background-position-x: 7px;

    margin: 7px 0 10px 0;
}
.input_dollar:focus-visible,
.input_percent:focus-visible,
.input_people:focus-visible {
    outline-color: #5baba2;
}
.input_people {
    // background: url("../assets/images/icon/icon-person.svg") 0 0 no-repeat;
    background-color: #f3f8fb;
    background-size: 15px;
    background-position-y: center;
    background-position-x: 7px;

    margin: 7px 0 10px 0;
}

.input_people,
.input_dollar {
    width: 95%;
}
